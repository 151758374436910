import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { navSections } from '../pages'

export const NavMenu = () => (
  <>
    <aside id="navmenu" className="menu is-hidden-touch">
      <ul className="menu-list">
        {navSections.map((section, i) => (
          <li key={i}>
            <ScrollLink
              activeClass="has-text-weight-bold"
              to={`${section}`}
              href={`#${section}`}
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              {section}
            </ScrollLink>
          </li>
        ))}
      </ul>
    </aside>
    <div className="columns menu-mobile is-centered is-mobile is-hidden-desktop">
      <div className="column is-10-tablet is-11-mobile mt-2">
        <ul className="menu-list is-flex is-flex-wrap-wrap">
          {navSections.map((section, i) => (
            <li key={i}>
              <ScrollLink
                activeClass="has-text-weight-bold"
                to={`${section}`}
                href={`#${section}`}
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                {section}
              </ScrollLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </>
)

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import '../styles/index.scss'
import { SEO } from '../components/seo'
import { NavMenu } from '../components/nav'

type OutboundLinkProps = {
  to: string
  children: React.ReactNode
  className?: string
}

const OutboundLink: React.FC<OutboundLinkProps> = ({
  to,
  children,
  className,
}) => (
  <a
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    className={className || ''}
  >
    {children}
  </a>
)

export type ContentfulContent = {
  content: any
}

export const navSections = ['about', 'projects', 'writing', 'talks', 'contact']

const textOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return <OutboundLink to={uri}>{children}</OutboundLink>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p style={{ marginTop: '1rem' }}>{children}</p>
    },
  },
}

export const HomepageScaffolding = (props: ContentfulContent) => {
  const { title, about, projectImages, writingSamples, talks } = props.content
  return (
    <SEO>
      <div className="site">
        <NavMenu />
        <div
          className="columns is-centered is-mobile mt-6"
          id={`${navSections[0]}`}
        >
          <div className="column is-8-desktop is-10-tablet is-11-mobile mt-6">
            <h1 className="title is-1 is-spaced">{title}</h1>
            <p className="subtitle"></p>
            {renderRichText(about, textOptions)}
          </div>
        </div>

        <div
          className="columns is-centered is-mobile mt-6"
          id={`${navSections[1]}`}
        >
          <div className="column is-8-desktop is-10-tablet is-11-mobile mt-6">
            <h2 className="title is-2">{navSections[1]}</h2>
            <div className="columns mt-5 is-multiline">
              {projectImages.map((image, i) => (
                <div className="column is-one-third project-image" key={i}>
                  <OutboundLink to={image.description}>
                    <div className="card">
                      <div className="card-image">
                        <figure className="image">
                          <GatsbyImage
                            image={image.gatsbyImageData}
                            alt={image.title}
                          />
                        </figure>
                      </div>
                      <div className="card-content is-overlay">
                        {image.title}
                      </div>
                    </div>
                  </OutboundLink>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="columns is-centered is-mobile mt-6"
          id={`${navSections[2]}`}
        >
          <div className="column is-8-desktop is-10-tablet is-11-mobile mt-6">
            <h2 className="title is-2 mb-5">{navSections[2]}</h2>
            {writingSamples.map((article, i) => (
              <p className="mb-5" key={i}>
                <OutboundLink to={article.url} className="subtitle is-4">
                  {article.title}
                </OutboundLink>
              </p>
            ))}
          </div>
        </div>

        <div
          className="columns is-centered is-mobile mt-6"
          id={`${navSections[3]}`}
        >
          <div className="column is-8-desktop is-10-tablet is-11-mobile mt-6">
            <h2 className="title is-2 mb-5">{navSections[3]}</h2>
            {talks.map((talk, i) => (
              <div key={i} className="mb-6">
                <h3 className="subtitle mb-3">{talk.title}</h3>
                {renderRichText(talk.text)}
                {talk.url && (
                  <OutboundLink to={talk.url} className="mt-5">
                    Link
                  </OutboundLink>
                )}
              </div>
            ))}
          </div>
        </div>

        <div
          className="columns is-centered is-mobile my-6"
          id={`${navSections[4]}`}
        >
          <div className="column is-8-desktop is-10-tablet is-11-mobile my-6">
            <h2 className="title is-2 mb-5">{navSections[4]}</h2>
            <p className="subtitle mt-5">
              <OutboundLink to="mailto:hello@samrabiyah.com">
                hello@samrabiyah.com
              </OutboundLink>
            </p>
            <p className="subtitle">
              <OutboundLink to="https://twitter.com/samrabiyah">
                @samrabiyah
              </OutboundLink>
            </p>
          </div>
        </div>
      </div>
    </SEO>
  )
}

export const HomepageFragment = graphql`
  fragment Homepage on Query {
    contentfulHomepage {
      title
      about {
        raw
      }
      projectImages {
        title
        description
        gatsbyImageData
      }
      writingSamples {
        title
        url
      }
      talks {
        title
        text {
          raw
        }
        url
      }
    }
  }
`

const Homepage = () => (
  <StaticQuery
    query={graphql`
      query {
        ...Homepage
      }
    `}
    render={(data) => <HomepageScaffolding content={data.contentfulHomepage} />}
  />
)

export default Homepage
